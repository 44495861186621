import request from "@/utils/request";


export function queryStudent(page, size, data) {
    return request({
        url: "/sys/studentInfo/queryStudents?page=" + page + "&size=" + size,
        method: "post",
        data,
    });
}

export function addStudent(data) {
    return request({
        url: "/sys/studentInfo/addStudent",
        method: "post",
        data,
    });
}

export function getStudent(id) {
    return request({
        url: "/sys/studentInfo/getOneStudent/" + id,
        method: "get",
    });
}

export function getStudentForm(id) {
    return request({
        url: "/sys/studentInfo/getStudent/" + id,
        method: "get",
    });
}

export function updateStudent(data) {
    return request({
        url: "/sys/studentInfo/updateStudent",
        method: "post",
        data,
    });
}


export function deleteStudent(data) {
    return request({
        url: "/sys/studentInfo/deleteStudents",
        method: "post",
        data,
    });
}

export function importStudent(data) {
    return request({
        url: "/sys/studentInfo/importStudent",
        method: "post",
        data,
    });
}



//学生学籍
export function getUsedEducation(id) {
    return request({
        url: "/sys/educationalInfo/getActiveEducationl/" + id,
        method: "get",
    });
}


export function getEducations(id) {
    return request({
        url: "/sys/educationalInfo/getEducationls/" + id,
        method: "get",
    });
}


export function addEducation(data) {
    return request({
        url: "/sys/educationalInfo/addEducational",
        method: "post",
        data,
    });
}

export function getEducation(id) {
    return request({
        url: "/sys/educationalInfo/getOneEducational/" + id,
        method: "get",
    });
}

export function updateEducation(data) {
    return request({
        url: "/sys/educationalInfo/updateEducational",
        method: "post",
        data,
    });
}


export function deleteEducation(data) {
    return request({
        url: "/sys/educationalInfo/deleteEducationals",
        method: "post",
        data,
    });
}

export function queryEducationalsDetailByTaskId(page, size, data) {
    return request({
        url: "/sys/educationalInfo/queryEducationalsDetailByTaskId?page=" + page + "&size=" + size,
        method: "post",
        data,
    });
}

/**
 * 异步上传学生信息
 */
export function importStudentAsyn(taskId, data) {
    return request({
        url: `/sys/studentInfo/importStudentAsyn?taskId=${taskId}`,
        method: "post",
        data,
    });
}
/**
 * 查询异步进度
 * @param {*} taskId 
 * @returns 
 */
export function exportprocess(taskId) {
    return request({
        url: `/sys/tool/up/exportprocess?taskNo=${taskId}`,
        method: "get"
    });
}
/**
 * 查询学生信息
 * @param {*} taskId 
 * @returns 
 */
export function getStudentLogsList(taskId) {
    return request({
        url: `/sys/studentInfo/getLogs/${taskId}`,
        method: "get"
    });
}
/**
 * 查询学历日志
 * @param {*} taskId 
 * @returns 
 */
export function getStudentEduLogsList(taskId) {
    return request({
        url: `/sys/educationalInfo/getLogs/${taskId}`,
        method: "get"
    });
}

/**
 *解绑学历
 * @param {*} taskId 
 * @returns 
 */
export function unbind(id) {
    return request({
        url: `/sys/educationalInfo/unbind/${id}`,
        method: "get"
    });
}

/**
 *查看采集信息
 * @param {*} taskId 
 * @returns 
 */
 export function sysStudentInfo(id) {
    return request({
        url: `/sys/studentInfo/getStudentAllInfo/${id}`,
        method: "get"
    });
}