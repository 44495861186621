import request from "@/utils/request";

//待邮寄批次列表
export function querymailList(data = {}, currentPage, pageSize) {
  return request({
    //   url: "/sys/orderDetail/queryBatchCheck?workDate="+data,
    url:
      "/sys/ems/mail/toSendList?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}

// 查询待打印面单列表
export function toPrintList(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/ems/mail/toPrintList?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}

// 查询待打印面单明细
export function toPrintListInfo(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/ems/mail/toPrintListInfo?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "POST",
    data,
  });
}

// 打印电子单面（整个批次打印）
export function mailPrintOrder(data) {
  return request({
    url: "/sys/ems/mail/printOrderNew",
    method: "POST",
    data,
  });
}

// 打印电子单面（单个批次打印）
export function printOrderOne(id) {
  return request({
    url: "/sys/ems/mail/printOrderOne/" + id,
    method: "get",
  });
}

//待确认邮寄
export function toConfirmListr(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/ems/mail/toConfirmList?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "POST",
    data,
  });
}

//待确认邮寄明细
export function toConfirmListInfo(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/ems/mail/toConfirmListInfo?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "POST",
    data,
  });
}

//确认邮寄
export function confirm(data) {
  return request({
    url: "/sys/ems/mail/confirm",
    method: "POST",
    data,
  });
}

//已邮寄
export function mailedList(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/ems/mail/mailedList?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "POST",
    data,
  });
}

//已邮寄明细
export function mailedListInfo(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/ems/mail/mailedListInfo?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "POST",
    data,
  });
}
//推送邮寄
export function emsMailPut(data = {}) {
  return request({
    url: "/sys/ems/mail/put",
    method: "POST",
    data,
    headers: { "Content-Type": "application/json" },
  });
}
