<template>
  <!-- 待邮寄页面 -->
  <div class="box">
    <!-- 搜索部分 -->
    <div style="display: flex; margin-bottom: 35px">
      <div class="condition-item">
        <label class="fn-14">任务名称</label>
        <el-input
          clearable
          v-model="searchBatchName"
          placeholder="请输入任务名称"
          autocomplete="off"
          size="small"
          style="width: 160px"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">摄影师</label>
        <el-input
          clearable
          v-model="searchPhotographer"
          placeholder="请输入摄影师"
          autocomplete="off"
          size="small"
          style="width: 160px"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">创建时间</label>
        <el-date-picker
          clearable
          v-model="searchBeginTime"
          align="right"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择开始日期"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
        <label class="fn-14" style="padding: 0 10px">-</label>
        <el-date-picker
          clearable
          align="right"
          type="date"
          v-model="searchEndTime"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择结束日期"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <el-button
          type="primary"
          size="small"
          style="width: 85px"
          @click="handsSearch"
          v-if="searchButton"
          >搜 索</el-button
        >
      </div>
    </div>
    <!-- 待邮寄列表盒子 -->
    <div style="display: flex; flex-direction: column; height: calc(100vh - 360px)">
      <el-table
        border
        :data="mailList"
        ref="multipleTable"
        style="width: 100%; text-align: center"
        height="100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        @selection-change="handleSelection"
      >
        <el-table-column align="center" fixed type="selection" width="50">
        </el-table-column>
        <el-table-column prop="title" label="任务名称" width="300" fixed>
        </el-table-column>
        <el-table-column align="center" prop="senderName" label="联系人" width="120">
        </el-table-column>
        <el-table-column align="center" prop="phone" label="联系人电话" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.phone }}</span></template
          >
        </el-table-column>

        <el-table-column align="center" prop="createTime" label="创建时间" width="240">
          <template slot-scope="scope">
            {{ formDateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="photographer" label="摄影师" width="120">
        </el-table-column>
        <el-table-column align="center" prop="totalCount" label="采集人数" width="120">
        </el-table-column>

        <el-table-column align="center" prop="mailStatus" label="邮寄状态" width="260">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.mailStatus === 1"
              type="warning"
              effect="plain"
              size="small"
              >待生成邮寄单号</el-tag
            >
            <el-tag
              v-else-if="scope.row.mailStatus === 2"
              type="primary"
              effect="plain"
              size="small"
              >正在生成邮寄单号
            </el-tag>
            <el-tag
              v-else-if="scope.row.mailStatus === 3"
              type="warning"
              effect="plain"
              size="warning"
              >待打印面单</el-tag
            >
            <el-tag
              v-else-if="scope.row.mailStatus === 4"
              type="warning"
              effect="plain"
              size="small"
              >待确认邮寄</el-tag
            >

            <el-tag
              v-else-if="scope.row.mailStatus === 5"
              type="primary"
              effect="plain"
              size="small"
              >正在提交邮寄</el-tag
            >
            <el-tag
              v-else-if="scope.row.mailStatus === 6"
              type="success"
              effect="plain"
              size="small"
              >已邮寄</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="address" label="地址" width="540"></el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div style="display: flex; justify-content: center; padding: 10px 0px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        style="display: flex; justify-content: center; flex-direction: row"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 底部盒子 -->
    <div class="foolter">
      <div class="info-box">
        合计:<span class="number">{{ numberPeople }}人</span>
      </div>
      <el-button
        class="push"
        type="primary"
        @click="pushMail"
        :loading="loading"
        :disabled="loading"
        v-if="pushMailButton"
        >推送邮寄</el-button
      >
    </div>
  </div>
</template>
<script>
import { querymailList } from "@/api/mail";
import { dateFormat } from "@/utils/date";
import axios from "axios";
import { getToken } from "@/utils/auth";
import { exportprocess } from "@/api/student";
import { formatPh, formatName } from "@/utils/validate";
import { queryPageButton } from "@/api/permission";
import { emsMailPut } from "../../../api/mail";

export default {
  name: "tobemailed",

  data() {
    return {
      value: "",
      dialogVisible: false,
      loading: false,
      searchPhotographer: "",
      searchBatchName: "",
      searchBeginTime: null,
      searchEndTime: null,
      mailList: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      numberPeople: 0,
      tableSelection: [],

      //权限按钮
      pushMailButton: false,
      searchButton: false,
      loadPage: null,
    };
  },
  created() {
    this.queryList();
    this.queryButton();
  },

  methods: {
    queryButton() {
      const data = 122;
      queryPageButton(data).then((resp) => {
        // console.log(resp);
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "pushMailButton") {
            this.pushMailButton = true;
          }
        }
      });
    },
    /**
     * 时间格式化
     *
     * **/
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryList();
    },
    // 选择
    handleSelection(val) {
      this.tableSelection = val;
      let num = 0;
      this.tableSelection
        .filter((item) => item.totalCount)
        .forEach((item) => {
          num += item.totalCount;
        });
      if (num == 0) {
        this.numberPeople = 0;
      } else {
        this.numberPeople = num;
      }
    },
    handsSearch() {
      this.currentPage = 1;
      this.queryList();
    },
    queryList() {
      // 搜索条件
      const datas = {
        photographer: this.searchPhotographer,
        batchName: this.searchBatchName,
        beginTime: this.formDateFormat(this.searchBeginTime),
        endTime: this.formDateFormat(this.searchEndTime),
      };
      // 列表数据

      querymailList(datas, this.currentPage, this.pageSize).then((resp) => {
        if (resp.code == 0) {
          let datas = resp.data.content;
          this.mailList = datas;
          // console.log(resp.data);
          this.total = resp.data.totalElements;
        }
      });
    },
    // 推送邮寄
    pushMail() {
      let json = JSON.parse(JSON.stringify(this.tableSelection));
      let postData = {};
      let jsonarray = [];
      json.map((item) => {
        postData = {
          id: item.id,
          collectType: item.collectType,
        };
        jsonarray.push(postData);
      });
      if (!jsonarray.length) {
        this.$notify({
          title: "警告",
          message: "选择的批次不能为空",
          type: "warning",
        });
      } else {
        this.loading = true;
        this.loadPage = this.$loading({
          lock: true,
          text: "推送邮寄中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.3)",
          customClass: "loading-class",
        });
        emsMailPut(jsonarray)
          .then((response) => {
            if (response.code == 0) {
              this.taskExportprocess(response.data.taskNo);
            } else {
              this.$notify.error({
                title: "错误",
                message: response.message,
              });
              this.loadPage.close();
              this.loading = false;
              this.numberPeople = 0;
            }
          })
          .catch((err) => {
            this.$notify({
              title: "警告",
              message: err.message,
              type: "warning",
            });
            this.loadPage.close();
            this.loading = false;
            this.queryList();
            this.numberPeople = 0;
          });
      }
    },
    /**
     * 查询任务进度
     */
    taskExportprocess(taskNo) {
      let that = this;
      exportprocess(taskNo).then((res) => {
        if (res.data.state == 0) {
          setTimeout(() => {
            that.taskExportprocess(taskNo);
          }, 500);
        } else if (res.data.state == 1) {
          that.$notify({
            title: "温馨提示",
            message: res.data.msg[0],
            type: "success",
          });
          this.loadPage.close();
          this.loading = false;
          this.numberPeople = 0;
          this.queryList();
        } else {
          this.$notify({
            title: "警告",
            message: res.data.msg[0],
            type: "warning",
          });
          this.loadPage.close();
          this.loading = false;
          this.numberPeople = 0;
          this.queryList();
        }
      });
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      }
    },
  },
};
</script>

<style scoped>
.el-checkbox__inner {
  border-radius: 20px !important;
}
/* 查看按钮 */
.d-btn {
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 5px;
}
/* 列表盒子 */
.box div.plan-box {
  margin: 10px;
}
/* 合计人数 */
.box div.foolter {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #f5f7fa;
}
.box div.foolter div.info-box span.number {
  padding: 0px 15px;
  color: #666;
}
.box div.foolter div.info-box span.number {
  padding: 0px 20px;
  color: #446cf3;
  font-size: 18px;
}
</style>
